@import '../../default.scss';

.preloader {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 100px auto;

    width: 262px;
    height: 81px;
    mask-image: url('../../../img/logo-mask.svg');
    background: #3a4fae48;

    animation: blur 600ms alternate ease-in-out infinite;

    .image-spin {
        position: absolute;
        z-index: -1;
        top: -10px;
        left: -6px;
        animation: bg 1600ms infinite ease-in alternate forwards;
        width: 44px;
        height: 44px;
        border-radius: 100px;

        &.right {
            top: auto;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 21px;
            border-radius: 0;
        }
    }

}

@keyframes bg {
    0% {
        background: $clr_primary;
    }

    50% {
        background: $clr_orange;
    }

    100% {
        background: $clr_blueLighten;
    }
}

@keyframes blur {
    0% {
        opacity: 1;
    }

    100% {

        opacity: .85;
    }
}